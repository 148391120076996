import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';
import clsx from 'clsx';
import { isBrowser } from '../helpers/isBrowser';

export const Face = ({ isAngry, faceReaction, browsUp, className }) => {

	// made with inspiration from: https://codepen.io/electricgarden/pen/YzzWMyM
	const svg = useRef(null);
	const leftPupil = useRef(null);
	const rightPupil = useRef(null);

	const MAX_EYE_TRAVEL = 22;

	// utility
	const clamp = (value, min, max) => {
		return value <= min ? min : value >= max ? max : value;
	};

	// animations
	const angryFaceTimeline = useRef(gsap.timeline({ duration: .5, paused: true}));

	const addAnimationsAngryFace = () => {
		gsap.set('#left-brow', {
			transformOrigin: 'center',
		});

		gsap.set('#right-brow', {
			transformOrigin: 'center',
		});

		angryFaceTimeline.current
			.to('#left-brow', {
				x: 20,
				y: 10,
				rotate: '20deg',
				
			}, 0)
			.to('#right-brow', {
				x: -20,
				y: 10,
				rotate: '-45deg',
			}, 0)
			.to('#left-mouth', {
				y: 0,
				transform: 'rotateX(120deg)',
				transformOrigin: 'center',
			}, 0);
	};

	const animateBrowsUp = () => {
		gsap.to('#brows', {
			y: -50,
			duration: .2,
		});
	};

	const animateBrowsDown = () => {
		gsap.to('#brows', {
			y: 0,
			ease: 'bounce',
			duration: .4,
		});
	};

	const animatePupil = (eye, mousePosition) => {
		const MOVEMENT_DAMPENING = 7;
		const svgRect = svg.current.getBoundingClientRect();
		const centerX = svgRect.left + eye.getBBox().x + eye.getBBox().width * .5;
		const centerY = svgRect.top + eye.getBBox().y + eye.getBBox().height;
		const vecToMouse = new THREE.Vector2().subVectors(mousePosition, new THREE.Vector2(centerX, centerY));
		const clampedMouseX = clamp(vecToMouse.x / MOVEMENT_DAMPENING, MAX_EYE_TRAVEL * -1, MAX_EYE_TRAVEL);
		const clampedMouseY = clamp(vecToMouse.y / MOVEMENT_DAMPENING, MAX_EYE_TRAVEL * -1, MAX_EYE_TRAVEL);
		gsap.set(eye, {
			x: clampedMouseX,
			y: clampedMouseY,
		});
	};

	const updateMousePosition = event => {
		const mousePosition = new THREE.Vector2(event.clientX, event.clientY);
		if (leftPupil.current && rightPupil.current) {
			animatePupil(leftPupil.current, mousePosition);
			animatePupil(rightPupil.current, mousePosition);
		}
	};

	useEffect(() => {
		if (isBrowser()) {
			addAnimationsAngryFace();
			// Following line is added to ensure the left-mouth has a transform matrix before animating.
			gsap.set('#left-mouth', { x: 0, y: 0 });
			window.addEventListener('mousemove', updateMousePosition);
			return () => {
				gsap.killTweensOf('#left-mouth');
				gsap.killTweensOf('#brows');
				gsap.killTweensOf('#left-brow');
				gsap.killTweensOf('#right-brow');
				gsap.killTweensOf(leftPupil.current);
				gsap.killTweensOf(rightPupil.current);
				window.removeEventListener('mousemove', updateMousePosition);
			};
		}
	}, []);

	useEffect(() => {
		if (isBrowser()) {
			isAngry ? angryFaceTimeline.current.play() : angryFaceTimeline.current.reverse();
		}
	}, [isAngry]);

	useEffect(() => {
		if (isBrowser() && faceReaction > 0) {
			gsap.fromTo('#brows', {
				y: 0,
			}, {
				y: 5,
				duration: .1,
				yoyo: true,
				repeat: 1,
			});
		}
	}, [faceReaction]);

	useEffect(() => {
		if(browsUp) {
			animateBrowsUp();
		} else {
			animateBrowsDown();
		}
	}, [browsUp]);

	return (
		<svg id="face" className={clsx('overflow-visible', className)} ref={svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333 254.34"
			onMouseDown={() => animateBrowsUp()}
			onMouseUp={() => animateBrowsDown()}
			onMouseLeave={() => animateBrowsDown()}
		>
			<g id="mouth">
				<path id="Path_1474" d="M191,253.84A13.91,13.91,0,0,1,191,226" fill="none" stroke="#020202" />
				<path id="left-mouth" d="M140.08,241.48s17.9,19.12,37,0" fill="none" stroke="#020202" />
			</g>
			<path id="eye-line" d="M34.62,219.74c8.86-9.28,23.46-15.34,40-15.34,15.11,0,28.62,5.07,37.59,13" fill="none" stroke="#000" />
			<g id="eyes">
				<path id="left-iris" d="M76.5,58.06A75.51,75.51,0,0,1,93.38,207.17c-.07,0,.07,0,0,0s-.43-.12-.55-.17c-.34-.11-.74-.22-1.21-.34-1.39-.35-3.3-.72-5.52-1.25a49.6,49.6,0,0,0-9.6-1c-8.51-.22-18.28,2.19-19.75,2.49l.88-.2A75.51,75.51,0,0,1,76.5,58.06Z" fill="#fff" stroke="#000" />
				<circle id="left-pupil" ref={leftPupil} cx="76.25" cy="135.06" r="42" />
				<circle id="right-iris" cx="256.5" cy="133.56" r="75.5" fill="#fff" stroke="#000" />
				<circle id="right-pupil" ref={rightPupil} cx="255.25" cy="135.06" r="42" />
			</g>
			<g id="brows">
				<path id="left-brow" d="M108.39,15.83a8.69,8.69,0,0,1-9.55,14.51l-.27-.19c-25.42-17.56-50.5-2.77-50.79-2.6a8.67,8.67,0,0,1-9-14.85C40.21,11.84,73.84-7.92,108.39,15.83Z" fill="#020202" />
				<path id="right-brow" d="M300.57,28.5a8.68,8.68,0,1,1-14.3,9.86c-17.44-25.51-46.15-20.68-46.48-20.63a8.67,8.67,0,1,1-3-17.07C238.35.35,276.83-6,300.57,28.5Z" fill="#020202" />
			</g>
		</svg>
	);
};
